import React, { useState, useEffect } from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';

const Services = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const cardsToShow = windowWidth >= 1024 ? 3 : 1;

  const services = [
    {
      title: "Actividades Nacionales Registradas",
      content: "Con más de 30 años de experiencia en el transporte de cargas generales y peligrosas, nos destacamos por nuestra especialización en los sectores portuario y agroquímico. Nuestra oficina central está ubicada en Quilmes, provincia de Buenos Aires, y contamos con una sucursal estratégica en Timbúes, provincia de Santa Fe. Nuestro parque automotor incluye más de 30 unidades tractoras propias, junto con una amplia variedad de equipos: cisternas, semirremolques con baranda, semirremolques playos, portacontenedores, rampas para descarga y autoelevadores, asegurando soluciones logísticas completas y eficientes.",
      imageSrc: "/images/actividades-nacionales.jpg",
      imageAlt: "Actividades Nacionales"
    },
    {
      title: "Transporte de calidad",
      content: "En Barracas Trucks, nos comprometemos a ofrecer servicios de transporte de la más alta calidad a nuestros clientes. Contamos con un equipo de conductores altamente capacitados y un parque automotor moderno, equipado con tecnología avanzada, como seguimiento satelital, tanques de gasoil extra y servicio de monitoreo de cubiertas, entre otros.",
      imageSrc: "/images/transporte-calidad.jpg",
      imageAlt: "Transporte de calidad"
    },
    {
      title: "Servicios de Depósito y Almacenamiento",
      content: "Contamos con amplias instalaciones de depósito estratégicamente ubicadas para satisfacer todas sus necesidades de almacenamiento. Nuestros depósitos están equipados con sistemas de seguridad avanzados y personal altamente capacitado para garantizar el manejo seguro y eficiente de su mercadería. Ofrecemos soluciones flexibles de almacenamiento a corto y largo plazo, con servicios complementarios de clasificación, empaque y distribución.",
      imageSrc: "/images/deposito.jpeg",
      imageAlt: "Depósito y almacenamiento"
    },
    {
      title: "Experiencia y profesionalismo",
      content: "Más de 30 años de experiencia en el transporte de cargas en Barracas Trucks somos reconocidos por nuestro profesionalismo y compromiso con la calidad en nuestro servicio. Estando presente en cada detalle, y buscando optimizar continuamente el servicio logístico. Nuestro objetivo es brindar un servicio de transporte confiable, eficiente y sustentable para satisfacer las necesidades de nuestros clientes.",
      imageSrc: "/images/experiencia-profesionalismo.jpg",
      imageAlt: "Experiencia y profesionalismo"
    },
    {
      title: "Compromiso con el medio ambiente",
      content: "En Barracas Trucks, somos conscientes de la importancia de cuidar el medio ambiente. Por eso, contamos con políticas y prácticas sustentables en todo el proceso logístico. Además, nuestro parque automotor cumple con las normas ambientales vigentes y contamos con programas de reciclaje y reutilización de materiales.",
      imageSrc: "/images/compromiso-ambiente.jpg",
      imageAlt: "Compromiso con el medio ambiente"
    }
  ];

  const navigateCarousel = (direction) => {
    if (direction === 'next') {
      setCurrentIndex((prevIndex) => 
        (prevIndex + 1) % services.length
      );
    } else {
      setCurrentIndex((prevIndex) => 
        prevIndex === 0 ? services.length - 1 : prevIndex - 1
      );
    }
  };

  const getVisibleServices = () => {
    const result = [];
    for (let i = 0; i < cardsToShow; i++) {
      const index = (currentIndex + i) % services.length;
      result.push(services[index]);
    }
    return result;
  };

  return (
    <section id="two" className="bg-gray-50 py-16">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold mb-12 text-center text-blue-600 hover:text-blue-800">
          Nuestros Servicios
        </h2>
        <div className="flex items-stretch gap-4">
          <button
            onClick={() => navigateCarousel('prev')}
            className="p-2 h-12 self-center rounded-full bg-gray-200 hover:bg-gray-300 transition-all transform hover:scale-110 flex-shrink-0"
            aria-label="Servicio anterior"
          >
            <ChevronLeft className="w-6 h-6 text-gray-600" />
          </button>

          <div className="flex-1 grid grid-cols-1 lg:grid-cols-3 gap-6 items-stretch">
            {getVisibleServices().map((service, index) => (
              <div key={`${currentIndex}-${index}`}>
                <ServiceCard {...service} />
              </div>
            ))}
          </div>

          <button
            onClick={() => navigateCarousel('next')}
            className="p-2 h-12 self-center rounded-full bg-gray-200 hover:bg-gray-300 transition-all transform hover:scale-110 flex-shrink-0"
            aria-label="Siguiente servicio"
          >
            <ChevronRight className="w-6 h-6 text-gray-600" />
          </button>
        </div>
      </div>
    </section>
  );
};

const ServiceCard = ({ title, content, imageSrc, imageAlt }) => {
  return (
    <div className="bg-white rounded-lg shadow-md overflow-hidden h-full flex flex-col">
      <div className="w-full h-48 relative">
        <img
          src={imageSrc}
          alt={imageAlt}
          className="w-full h-full object-cover"
        />
      </div>
      <div className="p-6 flex-1 flex flex-col">
        <h3 className="text-xl font-bold mb-4 text-gray-800">{title}</h3>
        <p className="text-gray-600 text-base leading-relaxed">
          {content}
        </p>
      </div>
    </div>
  );
};

export default Services;