import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import BarracasPage from './Pages/BarracasPage';
import Work from './Pages/Work';
import Claims from './Pages/Claims';
import NotFound from './Pages/NotFound';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<BarracasPage />} />
        <Route path="/trabaja" element={<Work />} />
        <Route path="/reclamos" element={<Claims />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
}

export default App;