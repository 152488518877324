import React, { useState, useEffect } from 'react';
import { Menu } from 'lucide-react';
import { scrollToElement } from '../utils/navigation';
import { Link } from 'react-router-dom';

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  // Cerrar el menú al hacer clic fuera de él
  useEffect(() => {
    const handleClickOutside = (event) => {
      const menuButton = document.getElementById('menu-button');
      const menuContent = document.getElementById('menu-content');

      if (isMenuOpen &&
        menuButton &&
        menuContent &&
        !menuButton.contains(event.target) &&
        !menuContent.contains(event.target)) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [isMenuOpen]);

  return (
    <header className="bg-dark-blue-900 text-gray-100 relative">
      <div className="container mx-auto px-4 py-6 flex justify-between items-center">
        <div className="flex items-center space-x-4">
          <div className="relative">
            <button
              id="menu-button"
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              className="p-2 hover:bg-dark-blue-800 rounded-lg transition-colors"
              aria-label="Menu principal"
            >
              <Menu className="h-6 w-6 text-gray-300 hover:text-white" />
            </button>

            {isMenuOpen && (
              <div
                id="menu-content"
                className="absolute top-full left-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-50"
              >
                <div className="py-1">
                  <Link
                    to="/trabaja"
                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                  >
                    Trabajá con nosotros
                  </Link>
                  <Link
                    to="/reclamos"
                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                  >
                    Reclamos
                  </Link>
                </div>
              </div>
            )}
          </div>
          <Link to="/">
            <img src="/images/logo.png" alt="Logo de Barracas Trucks" className="h-12" />
          </Link>
        </div>

        <nav>
          <ul className="flex space-x-4">
            <li>
              <a href="#contact" onClick={scrollToElement('contact')} className="text-gray-300 hover:text-white">
                <span className="sr-only">Contacto</span>
                <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                  <path fillRule="evenodd" d="M21.384,17.752a2.108,2.108,0,0,1-.522,1.359c-.089.092-.183.177-.276.264a3.132,3.132,0,0,1-.53.394,3.564,3.564,0,0,1-.677.321,4.858,4.858,0,0,1-.827.21,6.622,6.622,0,0,1-.911.074,11.018,11.018,0,0,1-2.151-.228A13.287,13.287,0,0,1,13.4,19.4a14.421,14.421,0,0,1-2.237-1.324,14.876,14.876,0,0,1-1.967-1.637,14.876,14.876,0,0,1-1.637-1.967A14.421,14.421,0,0,1,6.235,12.23a13.287,13.287,0,0,1-.944-2.09,11.018,11.018,0,0,1-.228-2.151,6.622,6.622,0,0,1,.074-.911,4.858,4.858,0,0,1,.21-.827,3.564,3.564,0,0,1,.321-.677,3.132,3.132,0,0,1,.394-.53c.087-.093.172-.187.264-.276a2.108,2.108,0,0,1,1.359-.522,2.134,2.134,0,0,1,.325.026,2.02,2.02,0,0,1,.317.089c.107.038.209.082.307.131a1.934,1.934,0,0,1,.277.188,1.855,1.855,0,0,1,.223.246l1.584,2.23a2.177,2.177,0,0,1,.29.483,1.936,1.936,0,0,1,.145.557,1.778,1.778,0,0,1-.037.574,1.945,1.945,0,0,1-.208.515l-.522.927a.587.587,0,0,0-.054.223.637.637,0,0,0,.054.223,3.327,3.327,0,0,0,.321.515,7.193,7.193,0,0,0,.5.584,9.213,9.213,0,0,0,.642.6,7.193,7.193,0,0,0,.584.5,3.327,3.327,0,0,0,.515.321.637.637,0,0,0,.223.054.587.587,0,0,0,.223-.054l.927-.522a1.945,1.945,0,0,1,.515-.208,1.778,1.778,0,0,1,.574-.037,1.936,1.936,0,0,1,.557.145,2.177,2.177,0,0,1,.483.29l2.23,1.584a1.855,1.855,0,0,1,.246.223,1.934,1.934,0,0,1,.188.277c.049.098.093.2.131.307a2.02,2.02,0,0,1,.089.317A2.134,2.134,0,0,1,21.384,17.752Z" clipRule="evenodd" />
                </svg>
              </a>
            </li>
            <li>
              <a href="https://www.linkedin.com/company/barracas-trucks-srl/" target="_blank" rel="noopener noreferrer" className="text-gray-300 hover:text-white">
                <span className="sr-only">LinkedIn</span>
                <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                  <path fillRule="evenodd" d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z" clipRule="evenodd" />
                </svg>
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/barracas_trucks/" target="_blank" rel="noopener noreferrer" className="text-gray-300 hover:text-white">
                <span className="sr-only">Instagram</span>
                <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                  <path fillRule="evenodd" d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z" clipRule="evenodd" />
                </svg>
              </a>
            </li>
            <li>
              <a href="https://api.whatsapp.com/send/?phone=5491139333372&text=Hola%2C+quisiera+conocer+m%C3%A1s+sobre+tus+servicios&type=phone_number&app_absent=0" target="_blank" rel="noopener noreferrer" className="text-gray-300 hover:text-white">
                <span className="sr-only">WhatsApp</span>
                <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                  <path fillRule="evenodd" d="M20.472,3.527C18.224,1.278,15.297,0,12.195,0C5.529,0,0.101,5.429,0.101,12.094c0,2.134,0.557,4.217,1.618,6.058L0,24l6.036-1.584c1.774,0.969,3.777,1.478,5.82,1.478h0.005c6.665,0,12.093-5.43,12.093-12.095C23.954,8.697,22.676,5.771,20.472,3.527z M12.195,22.079h-0.004c-1.804,0-3.575-0.486-5.121-1.402l-0.367-0.218l-3.808,0.999l1.018-3.717l-0.24-0.381c-1.009-1.606-1.542-3.461-1.542-5.366c0-5.546,4.514-10.061,10.064-10.061c2.688,0,5.212,1.047,7.111,2.947c1.899,1.899,2.944,4.424,2.943,7.114C22.25,17.566,17.737,22.079,12.195,22.079z M17.709,14.584c-0.303-0.151-1.789-0.883-2.067-0.984c-0.278-0.101-0.48-0.151-0.682,0.152c-0.202,0.302-0.783,0.984-0.96,1.185c-0.177,0.202-0.353,0.227-0.656,0.076c-0.303-0.151-1.279-0.471-2.436-1.503c-0.9-0.803-1.508-1.793-1.684-2.095c-0.177-0.302-0.019-0.465,0.133-0.615c0.136-0.135,0.303-0.353,0.454-0.529c0.151-0.177,0.202-0.303,0.303-0.504c0.101-0.202,0.05-0.378-0.025-0.529c-0.076-0.151-0.682-1.641-0.934-2.247c-0.246-0.59-0.496-0.51-0.682-0.519c-0.177-0.009-0.378-0.009-0.581-0.009c-0.202,0-0.53,0.076-0.808,0.378c-0.277,0.303-1.059,1.035-1.059,2.525c0,1.489,1.085,2.927,1.236,3.129c0.151,0.202,2.132,3.254,5.166,4.566c0.722,0.311,1.285,0.497,1.724,0.637c0.724,0.229,1.384,0.197,1.907,0.119c0.582-0.087,1.789-0.731,2.041-1.438c0.252-0.706,0.252-1.312,0.176-1.438C18.214,14.811,18.012,14.736,17.709,14.584z" clipRule="evenodd" />
                </svg>
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Header;