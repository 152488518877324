import React from 'react';
import Layout from '../Components/Layout';
import { Link } from 'react-router-dom';
import { Home, ArrowLeft } from 'lucide-react';

const NotFound = () => {
  return (
    <Layout>
      <div className="min-h-screen bg-gray-50 flex flex-col items-center justify-center px-4 py-16">
        <div className="text-center">
          <h1 className="text-9xl font-bold text-blue-600">404</h1>
          
          <div className="mt-4 mb-8">
            <h2 className="text-3xl font-semibold text-gray-800 mb-2">
              Página no encontrada
            </h2>
            <p className="text-gray-600">
              Lo sentimos, la página que estás buscando no existe o ha sido movida.
            </p>
          </div>

          <div className="flex flex-col sm:flex-row items-center justify-center gap-4">
            <Link
              to="/"
              className="flex items-center justify-center gap-2 px-6 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors w-full sm:w-auto"
            >
              <Home size={20} />
              <span>Ir al inicio</span>
            </Link>
            
            <button
              onClick={() => window.history.back()}
              className="flex items-center justify-center gap-2 px-6 py-3 bg-gray-200 text-gray-700 rounded-lg hover:bg-gray-300 transition-colors w-full sm:w-auto"
            >
              <ArrowLeft size={20} />
              <span>Volver atrás</span>
            </button>
          </div>
        </div>

        {/* Camión animado */}
        <div className="mt-16">
          <img
            src="/images/logo.png"
            alt="Logo Barracas Trucks"
            className="w-32 h-32 object-contain animate-bounce"
          />
        </div>
      </div>
    </Layout>
  );
};

export default NotFound;