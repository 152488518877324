import React from 'react';
import Layout from '../Components/Layout';
import useContactForm from '../utils/contact-form';

const Claims = () => {
  const {
    handleSubmit,
    errors,
    status,
    isSubmitting,
    clearStatus
  } = useContactForm('Reclamo');

  const onSubmit = async (e) => {
    e.preventDefault();
    const formData = Object.fromEntries(new FormData(e.target));
    const success = await handleSubmit(formData);
    if (success) {
      e.target.reset();
    }
  };

  return (
    <Layout>
      <section className="bg-gray-50 py-20">
        <div className="container mx-auto px-4">
          <div className="max-w-3xl mx-auto">
            <h2 className="text-3xl font-bold mb-4 text-blue-600 text-center">
              Reclamos y Sugerencias
            </h2>
            <p className="text-gray-600 mb-8 text-center">
              Su opinión es importante para nosotros. Complete el siguiente formulario para realizar un reclamo o sugerencia.
            </p>

            <div className="bg-white rounded-lg shadow-md p-8">
              <form onSubmit={onSubmit} className="space-y-8">
                {/* Datos Personales */}
                <div>
                  <h3 className="text-xl font-semibold mb-4">
                    Datos Personales <span className="text-gray-500 text-sm">(Opcional)</span>
                  </h3>
                  <div className="space-y-4">
                    <div>
                      <label htmlFor="from_name" className="block text-sm font-medium text-gray-700">
                        Nombre completo
                      </label>
                      <input
                        type="text"
                        name="from_name"
                        id="from_name"
                        placeholder="Su nombre"
                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                      />
                    </div>

                    <div>
                      <label htmlFor="from_email" className="block text-sm font-medium text-gray-700">
                        Email
                      </label>
                      <input
                        type="email"
                        name="from_email"
                        id="from_email"
                        placeholder="su.email@ejemplo.com"
                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                      />
                      {errors.email && (
                        <p className="mt-1 text-sm text-red-600">{errors.email}</p>
                      )}
                    </div>

                    <div>
                      <label htmlFor="phone" className="block text-sm font-medium text-gray-700">
                        Teléfono
                      </label>
                      <input
                        type="tel"
                        name="phone"
                        id="phone"
                        placeholder="+54 9 11 1234-5678"
                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                      />
                    </div>
                  </div>
                </div>

                {/* Datos del Incidente */}
                <div>
                  <h3 className="text-xl font-semibold mb-4">
                    Datos del Incidente <span className="text-red-500">*</span>
                  </h3>
                  <div className="space-y-4">
                    <div>
                      <label htmlFor="type" className="block text-sm font-medium text-gray-700">
                        Tipo de Reporte *
                      </label>
                      <select
                        name="type"
                        id="type"
                        required
                        aria-required="true"
                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                      >
                        <option value="">Seleccione una opción</option>
                        <option value="reclamo">Reclamo</option>
                        <option value="sugerencia">Sugerencia</option>
                        <option value="felicitacion">Felicitación</option>
                      </select>
                      {errors.type && (
                        <p className="mt-1 text-sm text-red-600">{errors.type}</p>
                      )}
                    </div>

                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                      <div>
                        <label htmlFor="incident_date" className="block text-sm font-medium text-gray-700">
                          Fecha del incidente *
                        </label>
                        <input
                          type="date"
                          name="incident_date"
                          id="incident_date"
                          required
                          aria-required="true"
                          className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                        />
                        {errors.incident_date && (
                          <p className="mt-1 text-sm text-red-600">{errors.incident_date}</p>
                        )}
                      </div>

                      <div>
                        <label htmlFor="incident_time" className="block text-sm font-medium text-gray-700">
                          Hora del incidente *
                        </label>
                        <input
                          type="time"
                          name="incident_time"
                          id="incident_time"
                          required
                          className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                        />
                        {errors.incident_time && (
                          <p className="mt-1 text-sm text-red-600">{errors.incident_time}</p>
                        )}
                      </div>
                    </div>

                    <div>
                      <label htmlFor="location" className="block text-sm font-medium text-gray-700">
                        Ubicación del incidente *
                      </label>
                      <input
                        type="text"
                        name="location"
                        id="location"
                        required
                        placeholder="Ej: Ruta 3 km 50, Ciudad, Provincia"
                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                      />
                      {errors.location && (
                        <p className="mt-1 text-sm text-red-600">{errors.location}</p>
                      )}
                    </div>

                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                      <div>
                        <label htmlFor="unit_number" className="block text-sm font-medium text-gray-700">
                          Número de unidad (si lo conoce)
                        </label>
                        <input
                          type="text"
                          name="unit_number"
                          id="unit_number"
                          placeholder="Ej: Unidad 123"
                          className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                        />
                      </div>

                      <div>
                        <label htmlFor="plate_number" className="block text-sm font-medium text-gray-700">
                          Patente del vehículo (si la vio)
                        </label>
                        <input
                          type="text"
                          name="plate_number"
                          id="plate_number"
                          placeholder="Ej: AB123CD"
                          className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                        />
                      </div>
                    </div>

                    <div>
                      <label htmlFor="message" className="block text-sm font-medium text-gray-700">
                        Descripción detallada *
                      </label>
                      <textarea
                        name="message"
                        id="message"
                        rows="6"
                        required
                        placeholder="Por favor, describa el incidente o sugerencia con el mayor detalle posible"
                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                      ></textarea>
                      {errors.message && (
                        <p className="mt-1 text-sm text-red-600">{errors.message}</p>
                      )}
                    </div>
                  </div>
                </div>

                <div className="text-center">
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="w-full md:w-auto px-6 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50"
                  >
                    {isSubmitting ? 'Enviando...' : 'Enviar'}
                  </button>

                  {status.message && (
                    <p className={`mt-4 text-${status.type === 'success' ? 'green' : 'red'}-600`}>
                      {status.message}
                    </p>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Claims;